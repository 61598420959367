import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { ICustomerList, ICustomerListDOM } from "../../api/interfaces/interfaz_customer";

import {
  deleteManyCustomers,
  getAllCustomers,
  getExportCustomers,
  getExportCustomersByDistributorID,
  getExportCustomersByGroupId,
  getExportCustomersByID,
} from "../../api/services/customer_services";

import { getAllUsersById } from "../../api/services/user_services";
import { typeUserData } from "../../constants/userTypes";
import { ApplicationContext } from "../../context/ApplicationContext";
import { IRootState } from "../../store/redux";
import useAsync from "../useAsync";
import useFetchAndLoad from "../useFetchAndLoad";
import { createAddapterCustomer } from "./adapters/customerAdapter";
import { t } from "i18next";
import useToastMui from "../Toasts/useToastMui";
import { useTranslation } from "react-i18next";

const useCustomers = (
  username: string,
  firstName: string,
  lastName: string,
  zipCode: string,
  city: string,
  country: string,
  type: string,
  business: string,
  termsAccepted: string,
  privacyPolicyAccepted: string,
  companyName: string,
  email: string,
  phone: string,
  language: string,
  date_inscription: string,

  groupId?: string,
  DistributorId?: string
) => {
  const { setContentsListID, setExportToggle, setCustomersLength, customerPage, setCustomerPage } = useContext(ApplicationContext);
  const { handleCreateToast } = useToastMui();
  //Redux
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const { sub } = profile;

  const [customerArray, setCustomerArray] = useState<ICustomerListDOM[]>();
  const [lastPage, setLastPage] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const [deletedToogle, setDeletedToogle] = useState(false);
  const limit = 10;
  const { i18n } = useTranslation();

  const [errorDeleteAlert, setErrorDeleteAlert] = useState<boolean[]>([false, false, false]);

  const { loading, callEndpoint } = useFetchAndLoad();

  //to sort list by api

  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  const getQuery = (
    username: string,
    firstName: string,
    lastName: string,
    zipCode: string,
    city: string,
    country: string,
    type: string,
    business: string,
    termsAccepted: string,
    privacyPolicyAccepted: string,
    companyName: string,
    email: string,
    phone: string,
    language: string,
    date_inscription: string,
    groupId?: string,
    DistributorId?: string,
    sort?: string
  ) => {
    const queryKeys: any = {
      username: username,
      first_name: firstName,
      last_name: lastName,
      zipcode: zipCode,
      city: city,
      country: country,
      account_type: type,
      business: business,
      terms_accepted: termsAccepted,
      privacy_policy_accepted: privacyPolicyAccepted,
      associate_distributor: DistributorId,
      associate_group: DistributorId ? "" : groupId,
      company_name: companyName,
      email: email,
      company_phone_number: phone,
      language: language ? (language === `${t("LANGUAGE-LIST-ENGLISH")}` ? "English" : "French") : "",
      created_at: date_inscription,
      sort: sort,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const loadCustomers = async (
    username: string,
    firstName: string,
    lastName: string,
    zipCode: string,
    city: string,
    country: string,
    type: string,
    business: string,
    termsAccepted: string,
    privacyPolicyAccepted: string,
    companyName: string,
    email: string,
    phone: string,
    language: string,
    date_inscription: string,
    nowPage: number,
    groupId?: string,
    DistributorId?: string
  ) => {
    //! important in groups and users
    //In the use case of distributor if a user is a group or distributor
    //The data will be filtered by the distributor or group assossiated
    let userData: any = {};
    if (sub) {
      const responseUser = getAllUsersById(sub);
      userData = (await responseUser).data;
    }

    let query = getQuery(
      username,
      firstName,
      lastName,
      zipCode,
      city,
      country,
      type,
      business,
      termsAccepted,
      privacyPolicyAccepted,
      companyName,
      email,
      phone,
      language,
      date_inscription,
      nameRolSelected === typeUserData.group
        ? userData?.result?.groups_data?._id
          ? userData?.result?.groups_data?._id
          : "-.-.-"
        : groupId,
      nameRolSelected === typeUserData.distributor
        ? userData?.result?.distributors_data?._id
          ? userData?.result?.distributors_data?._id
          : "-.-.-"
        : DistributorId,
      typeSort
    );
    query = {
      ...query,
      limit: limit,
      offset: (nowPage - 1) * limit,
    };

    const response = await callEndpoint(
      getAllCustomers({ query }),
      () => (!groupId || !DistributorId) && handleCreateToast(t("ALERT-CUSTOMERS-ERROR-GET"), "error")
    );
    return response;
  };

  const adaptInfo = (data: ICustomerList) => {
    const array: ICustomerListDOM[] = createAddapterCustomer(data?.result?.items);
    setCustomerArray(array);
    setLastPage(data?.result?.total);
    setCustomersLength(data?.result?.total);
  };

  useAsync(
    () =>
      loadCustomers(
        username,
        firstName,
        lastName,
        zipCode,
        city,
        country,
        type,
        business,
        termsAccepted,
        privacyPolicyAccepted,
        companyName,
        email,
        phone,
        language,
        date_inscription,
        customerPage,
        groupId,
        DistributorId
      ),
    adaptInfo,
    () => {},
    [customerPage, searchToggle, deletedToogle, typeSort]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCustomerPage(value);
  };

  const onSearch = () => {
    setSearchToggle((value) => !value);
    setCustomerPage(1);
  };

  const deleteManyCustomer = async (customerID: string[]) => {
    try {
      const response = deleteManyCustomers(customerID);
      const data = (await response).data;
      handleCreateToast(t("ALERT-CUSTOMERS-SUCCESS-DELETE"), "success");
      return data;
    } catch (error: any) {
      console.log("error deleted customer");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-CUSTOMERS-ERROR-DELETE"), "error");
    } finally {
      console.log("end");
      setContentsListID([]);
      setCustomerPage(1);
      setDeletedToogle(!deletedToogle);
    }
  };

  const exportCustomer = async (checkedType: string, customerListID: string[], checkNeedToExport: boolean[]) => {
    setExportToggle(true);
    try {
      if (checkNeedToExport[0] === true) {
        if (sub) {
          const response = getAllUsersById(sub);
          const data = (await response).data;
          if (nameRolSelected === typeUserData.distributor) {
            if (data?.result?.distributors_data?._id) {
              await getExportCustomersByDistributorID(checkedType, data?.result?.distributors_data?._id);
            }
          } else if (nameRolSelected === typeUserData.group) {
            await getExportCustomersByGroupId(checkedType, data?.result?.groups_data?._id);
          } else {
            await getExportCustomers(checkedType);
          }
        }
      }
      if (checkNeedToExport[1] === true) {
        if (customerListID.length) {
          await getExportCustomersByID(checkedType, customerListID, i18n.language === "fr" ? "Client" : "Customer");
        } else {
          let userData: any = {};
          if (sub) {
            const responseUser = getAllUsersById(sub);
            userData = (await responseUser).data;
          }

          let query = getQuery(
            username,
            firstName,
            lastName,
            zipCode,
            city,
            country,
            type,
            business,
            termsAccepted,
            privacyPolicyAccepted,
            companyName,
            email,
            phone,
            language,
            date_inscription,
            nameRolSelected === typeUserData.group
              ? userData?.result?.groups_data?._id
                ? userData?.result?.groups_data?._id
                : "-.-.-"
              : groupId,
            nameRolSelected === typeUserData.distributor
              ? userData?.result?.distributors_data?._id
                ? userData?.result?.distributors_data?._id
                : "-.-.-"
              : DistributorId
          );
          query = {
            ...query,
          };
          await getExportCustomersByID(checkedType, customerListID, i18n.language === "fr" ? "Client" : "Customer", query);
        }
      }
    } catch (error: any) {
      console.log("error export customer");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(t("ALERT-CUSTOMERS-ERROR-EXPORT"), "error");
    } finally {
      console.log("end");
      setExportToggle(false);
    }
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    customerArray,
    loading,
    exportCustomer,
    deleteManyCustomer,
    errorDeleteAlert,
    setErrorDeleteAlert,
    currentPage: customerPage,
    lastPage,
    handleChange,
    setCurrentPage: setCustomerPage,
    limit,
    onSearch,
    typeSort,
    incrementCount,
  };
};

const service = {
  useCustomers,
};
export default service;
export { useCustomers };
