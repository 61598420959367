import { useState, useEffect, useContext } from "react";
import { getAllUsersByStatus, getAllUsersById, checkSession } from "../../api/services/user_services";
import { getExchangesCount } from "../../api/services/exchanges_services";

import { ApplicationContext } from "../../context/ApplicationContext";
import useFetchAndLoad from "../useFetchAndLoad";
import useAsync from "../useAsync";
import { IExchangesCount } from "../../api/interfaces/interfaz_exchanges";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import { typeUserData } from "../../constants/userTypes";

const useGetExchanges = (token?: string, toggle?: boolean, start?: boolean) => {
  const {
    setExchangesDraftLength,
    setExchangesAbandonnedLength,
    setExchSubmittedDraftLength,
    setExchangesAnalisisLength,
    setExchangesAcceptedLength,
    setExchangesAviableLength,
    setExchangeExchangeddLength,
    setExchangesRefusedLength,
    setExchangesRecycledLength,
    setErrorCheckSession,
  } = useContext(ApplicationContext);

  const { setUserDisabledLength, setUserEnabledLength, setUserPendingLength, setUserWebServicesLength } = useContext(ApplicationContext);

  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const { sub } = profile;

  useEffect(() => {
    (async () => {
      if (token || toggle) {
        await checkSessionFunction();
      }
    })();
  }, []);

  const checkSessionFunction = async () => {
    try {
      await checkSession();
      setErrorCheckSession(false);
    } catch (e) {
      setErrorCheckSession(true);
    }
  };

  const { loading, callEndpoint } = useFetchAndLoad();
  const getQuery = (groupId?: string, distributorId?: string) => {
    const queryKeys: any = {
      group_id: groupId,
      distributor_id: distributorId,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const handleExchangesCount = async () => {
    if (start) return;
    if (window.location.pathname.includes("/forgot-password/validate")) return;
    if (window.location.pathname.includes("/confirm-email")) return;
    if (window.location.pathname === "/" && !token) return;
    let userData: any = {};
    if (sub) {
      const response = getAllUsersById(sub);
      userData = (await response).data;
    }
    let query = getQuery(
      nameRolSelected === typeUserData.group ? (userData?.result?.groups_data?._id ? userData?.result?.groups_data?._id : "-.-.-") : "",
      nameRolSelected === typeUserData.distributor
        ? userData?.result?.distributors_data?._id
          ? userData?.result?.distributors_data?._id
          : "-.-.-"
        : ""
    );
    const response = await callEndpoint(getExchangesCount({ query }));
    return response;
  };

  const adaptInfo = (data: IExchangesCount) => {
    const draft = intToString(data?.result?.draft);
    const abandonned = intToString(data?.result?.abandonned);
    const submitted = intToString(data?.result?.submitted);
    const analysis = intToString(data?.result?.analysis);
    const accepted = intToString(data?.result?.accepted);
    const available = intToString(data?.result?.available);
    const exchanged = intToString(data?.result?.exchanged);
    const refused = intToString(data?.result?.refused);
    const recycled = intToString(data?.result?.recycled);
    setExchangesDraftLength(draft);
    setExchangesAbandonnedLength(abandonned);
    setExchSubmittedDraftLength(submitted);
    setExchangesAnalisisLength(analysis);
    setExchangesAcceptedLength(accepted);
    setExchangesAviableLength(available);
    setExchangeExchangeddLength(exchanged);
    setExchangesRefusedLength(refused);
    setExchangesRecycledLength(recycled);
  };

  useAsync(
    () => handleExchangesCount() as any,
    adaptInfo,
    () => {},
    [toggle]
  );

  const intToString = (num: any) => {
    num = num?.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
  };

  return { loading, handleExchangesCount };
};

const service = {
  useGetExchanges,
};
export default service;
export { useGetExchanges };
